import React, { useState, useEffect, useMemo, memo, useCallback } from "react";
import {
  GoogleMap,
  Marker,
  Polyline,
  LoadScript,
} from "@react-google-maps/api";
import mapStyle from "./data/map_style.json";
import io from "socket.io-client";
import { useLocation } from "react-router-dom";
import axios from "axios";
import styles from "./styles/MapPage.module.css";
import {
  Star,
  EmojiTransportation,
  DirectionsCar,
  KeyboardDoubleArrowDown,
  PinDrop,
} from "@mui/icons-material";

const containerStyle = {
  width: "100%",
  height: "100vh",
};

const MemoizedGoogleMap = memo(
  ({ position, path, actualPath, center, options }) => (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center || undefined}
      zoom={12}
      options={{ styles: mapStyle }}
    >
      {position && (
        <Marker
          position={position}
          icon={{
            url: "/images/car4.png",
            scaledSize: new window.google.maps.Size(50, 50),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(15, 15),
            rotation: position.bearing || 0,
          }}
        />
      )}
      {path.length > 0 && <Marker position={path[0]} />}
      {path.length > 0 && (
        <Polyline path={path} options={{ strokeColor: "#e2b880" }} />
      )}
      {actualPath.length > 0 && (
        <Polyline path={actualPath} options={{ strokeColor: "#000000" }} />
      )}
    </GoogleMap>
  )
);

function MapPage() {
  const [actualPath, setActualPath] = useState([]);
  const [position, setPosition] = useState();
  const [center, setCenter] = useState();
  const [tripId, setTripId] = useState(null);
  const location = useLocation();
  const Id = location.pathname.split("/")[1]; // Extract the id from the URL path
  const [path, setPath] = useState([]);
  const [open, setOpen] = useState(true);
  const [detail, setDetail] = useState(null);

  const apiUrl = process.env.REACT_APP_DETAILS_API;
  const socketUrl = process.env.REACT_APP_SOCKET_API;
  const imgUrl = process.env.REACT_APP_IMAGE_DOMAIN;

  const getInitials = (str) => {
    const results = [];
    const wordArray = str?.split(" ");
    for (let i = 0; i < 2 && i < wordArray?.length; i++) {
      results.push(wordArray[i][0]);
    }
    return results.join("");
  };

  useEffect(() => {
    setTripId(Id);

    // Connect to the socket server
    const socket = io(`${socketUrl}`);

    // Handle connection error
    socket.on("connect_error", (err) => {
      console.error("Socket connection error:", err);
      // Display an error message to the user
      alert("Failed to connect to the server. Seems the trip has ended!!");
    });

    socket.on("connect", () => {
      console.log("ID from Socket:", socket.id);
    });

    console.log("New trip:", tripId);

    // Listen to Cordinates events for the specific trip ID
    socket.on(tripId, (Cordinates) => {
      console.log("Cordinates:", Cordinates);
      // Process the Cordinates data here
      const { payload } = Cordinates;
      if (payload) {
        const { projected, actual, position } = payload;
        if (projected && projected.path && projected.path.length > 0) {
          const validPath = projected.path.filter(
            (point) =>
              typeof point.latitude === "number" &&
              typeof point.longitude === "number" &&
              isFinite(point.latitude) &&
              isFinite(point.longitude)
          );
          const mappedPath = validPath.map((point) => ({
            lat: point.latitude,
            lng: point.longitude,
          }));
          setPath(mappedPath);
          const firstValidPoint = mappedPath[0];
          if (firstValidPoint) {
            setCenter(firstValidPoint);
          } else {
            setCenter(null);
          }
        } else {
          setPath([]);
          setCenter(null);
        }
        if (actual && actual.length > 0) {
          const validActualPath = actual.filter(
            (point) =>
              typeof point.latitude === "number" &&
              typeof point.longitude === "number" &&
              isFinite(point.latitude) &&
              isFinite(point.longitude)
          );
          const mappedActualPath = validActualPath.map((point) => ({
            lat: point.latitude,
            lng: point.longitude,
          }));
          setActualPath(mappedActualPath);
        } else {
          setActualPath([]);
        }
        if (position) {
          if (
            typeof position.latitude === "number" &&
            typeof position.longitude === "number" &&
            isFinite(position.latitude) &&
            isFinite(position.longitude)
          ) {
            setPosition({ lat: position.latitude, lng: position.longitude });
          } else {
            setPosition(null);
          }
        } else {
          setPosition(null);
        }
      }
    });

    // Join room corresponding to trip ID
    socket.emit("join_trip", { tripId });

    // Clean up on component unmount
    return () => {
      socket.disconnect();
    };
  }, [tripId, Id, socketUrl]);

  useEffect(() => {
    const getDetails = async () => {
      try {
        const res = await axios.get(`${apiUrl}` + Id);
        setDetail(res.data);
      } catch (error) {
        console.error("Failed to fetch trip details:", error);
      }
    };
    getDetails();
  }, [Id, apiUrl]);

  const memoizedMapOptions = useMemo(() => ({ styles: mapStyle }), []);
  const handleToggleOpen = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  console.log("projected", path);
  console.log("actual", actualPath);
  console.log("position", position);
  console.log("details", detail);

  return (
    <div className={styles.container}>
      <div className={styles.mapContainer}>
        <LoadScript googleMapsApiKey={process.env.REACT_APP_MAP_API}>
          <MemoizedGoogleMap
            position={position}
            path={path}
            actualPath={actualPath}
            center={center}
            options={memoizedMapOptions}
          />
        </LoadScript>
      </div>
      {open && (
        <div className={styles.cardContainer}>
          <div className={styles.customerDetails}>
            <div className={styles.customerBox}>
              <div className={styles.customerText}>
                <h4 className={styles.customerTitle}>
                  {detail?.data?.customer?.firstName +
                    " " +
                    detail?.data?.customer?.lastName}
                </h4>
              </div>
              {detail?.data?.customer?.imageUrl ? (
                <img
                  src={`${imgUrl}?filename=${detail?.data?.customer?.imageUrl}`}
                  alt={`${detail?.data?.customer?.firstName} ${detail?.data?.customer?.lastName}`}
                  className={styles.customerImg}
                />
              ) : (
                <div className={styles.getInitial}>
                  <h1>
                    {getInitials(
                      detail?.data?.customer?.firstName +
                        " " +
                        detail?.data?.customer?.lastName
                    )}
                  </h1>
                </div>
              )}
            </div>
            <div className={styles.vehicleDetails}>
              <div className={styles.vehicleBox}>
                <p className={styles.textSubtitle}>
                  <PinDrop /> {detail?.data?.pickup?.name}
                </p>
                <KeyboardDoubleArrowDown />
                <p className={styles.textSubtitle}>
                  <EmojiTransportation /> {detail?.data?.destination?.name}
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div className={styles.driverBox}>
            <img
              src="/images/luxury.png"
              alt=""
              className={styles.driverBackgroundImg}
            />
            <div className={styles.driverImgContainer}>
              <div className={styles.driverText}>
                <h4 className={styles.driverTitle}>
                  {detail?.data?.driver?.firstName +
                    " " +
                    detail?.data?.driver?.lastName}
                </h4>
              </div>
              {detail?.data?.driver?.imageUrl ? (
                <img
                  src={`${imgUrl}?filename=${detail?.data?.driver?.imageUrl}`}
                  alt={`${detail?.data?.driver?.firstName} ${detail?.data?.driver?.lastName}`}
                  className={styles.driverImg}
                />
              ) : (
                <div className={styles.getInitial}>
                  <h1>
                    {getInitials(
                      detail?.data?.driver?.firstName +
                        " " +
                        detail?.data?.driver?.lastName
                    )}
                  </h1>
                </div>
              )}
              <div className={styles.rating}>
                {detail?.data?.driver?.rating}
                <Star style={{ fontSize: "18px" }} />
              </div>
            </div>

            <div className={styles.vehicleDetailsContainer}>
              <div className={styles.vehicleDetails}>
                <div className={styles.driverDetailBox}>
                  <p className={styles.textSubtitle}>
                    <DirectionsCar /> {detail?.data?.vehicle?.make?.name}{" "}
                    {detail?.data?.vehicle?.model?.name}{" "}
                    {detail?.data?.vehicle?.numberPlate}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={styles.mobileFooter}>
        {!open && (
          <img
            src="./images/chevron-up.svg"
            alt=""
            onClick={handleToggleOpen}
          />
        )}
        {open && (
          <img
            src="./images/chevron-down.svg"
            alt=""
            onClick={handleToggleOpen}
          />
        )}
      </div>
    </div>
  );
}

export default MapPage;
